<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <v-btn :to="{ name: 'articles' }" class="my-3">&laquo; ALL NEWS</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader class="container"
                           height="300"
                           width="1000"
                           type="heading, text, divider, image, divider, paragraph@3"
                           :loading="loading">
          <v-container v-if="!loading && !failedLoading" class="article-container">
            <v-row>
              <v-col>
                <h1>{{ post.title }}</h1>
              </v-col>
            </v-row>
            <v-row class="subtitle-1 mb-3">
              <v-col>
                <template v-if="post.author">
                  <span>by {{ post.author.username }}</span>
                  <span class="mx-1">&boxv;</span>
                </template>
                <span>{{ post.publishedAt | moment('LLL') }}</span>
              </v-col>
            </v-row>
            <!--      <template v-if="post.cover_image != null">-->
            <!--        <v-divider class="my-5"/>-->
            <!--        <v-row>-->
            <!--          <v-col>-->
            <!--            <lightbox max-height="250" contain-->
            <!--                      :lazy-src="$contentImage(post.cover_image, 'thumbnail')"-->
            <!--                      :preview-src="$contentImage(post.cover_image, 'medium')"-->
            <!--                      :src="$contentImage(post.cover_image, null)"/>-->
            <!--          </v-col>-->
            <!--        </v-row>-->
            <!--        <v-divider class="my-5"/>-->
            <!--      </template>-->
            <v-row>
              <v-col>
                <v-md-preview :text="post.content" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn :to="{ name: 'articles' }" class="my-3">&laquo; ALL NEWS</v-btn>
              </v-col>
            </v-row>
            <template v-if="post.attached_files.length > 0">
              <v-divider class="my-5"/>
              <v-row>
                <v-carousel hide-delimiter-background>
                  <v-carousel-item v-for="(file, i) in post.attached_files" :key="i">
                    <lightbox v-if="file.mime.indexOf('image/') >= 0"
                              :lazy-src="$contentImage(file, 'thumbnail')"
                              :preview-src="$contentImage(file, 'medium')"
                              :src="$contentImage(file)"/>
                    <v-sheet v-else height="100%" tile>
                      <v-row class="fill-height" align="center" justify="center">
                        <a :href="$api.content.qualify(file.url).href"
                           :download="file.name"
                           target="_blank"
                           class="text-h4 text-decoration-none">
                          Download {{ file.name }} ({{ file.size * 1000 | numFormat('0 b') }})
                        </a>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
            </template>
          </v-container>
          <div v-else>
            <v-alert type="error">
              Failed to load content.
              <div class="mt-3">
                <router-link :to="{ name: 'articles' }" class="white--text">&laquo; back to all news</router-link>
              </div>
            </v-alert>
          </div>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Lightbox from "@/components/Lightbox.vue";
import {BG_TYPE} from "@/store/options";
// import {BG_TYPE} from "@/store/options.js";
// import store from "@/store";

export default {
  components: {Lightbox},

  props: [
    "article_id"
  ],

  data() {
    return {
      failedLoading: false,
    }
  },

  async created() {
    await this.$store.dispatch('articles/loadAll');

    setTimeout(() => {
      if (this.post === undefined)
        this.failedLoading = true;
    }, 5000);
  },

  async mounted() {
    await this.updateBackground();
  },

  computed: {
    loading() {
      return this.post === undefined && !this.failedLoading;
    },

    post() {
      const articleId = Number(this.article_id);
      if (isNaN(articleId))
        return undefined;

      return this.$store.getters["articles/all"].find(a => a.id === articleId);
    }
  },

  watch: {
    async post() {
      if (this.post === undefined)
        return;

      this.failedLoading = false;

      await this.updateBackground();
    }
  },

  methods: {
    async updateBackground() {
      if (!this.post)
        return;

      if (!this.post.cover_image)
        return;

      const url = this.$contentImage(this.post.cover_image);
      if (!url)
        return;

      await this.$store.dispatch('options/updateBackground', {
        url,
        type: BG_TYPE.image,
      });
    }
  },
};
</script>

<style lang="scss">
.article-container {
  margin: auto;
  max-width: 1000px;
  text-shadow: black 0 0 10px;
  background: rgba(#151315, 0.6);
  border-radius: 1rem;

  img {
    width: 80%;
  }

  p {
    line-height: 1.75rem;
    font-size: 1rem;
    text-align: justify;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0;

    &:first-child {
      padding-top: 1rem;
    }

    &:last-child {
      padding-bottom: 1rem;
    }
  }
}
</style>
