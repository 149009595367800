<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="8" md="6">
        <h1 ref="news_header">News</h1>

        <v-pagination class="mt-5 mb-3" circle v-model="display_page" :length="pages_available"/>

        <v-skeleton-loader type="article@4" :loading="current_page_items.length === 0">
          <v-container fluid>
            <template v-for="article in current_page_items">
              <v-hover v-slot:default="{ hover }">
                <v-row class="article-row pointer rounded" :class="hover ? 'elevation-12 bg-transparent-cta' : null"
                       @click="$router.push({ name: 'article-detail', params: { article_id: article.id } })">
                  <v-col cols="12" sm="3">
                    <v-img :lazy-src="$contentImage(article.cover_image, 'thumbnail')"
                           :src="$contentImage(article.cover_image, 'large', true, true)"
                           eager contain min-height="80px" max-height="150px" style="border-radius: 0.25rem" />
                  </v-col>

                  <v-col cols="12" sm="9" class="flex flex-column">
                    <h2 v-html="article.title"></h2>
                    <summary v-html="article.summary"></summary>
                    <small class="text--disabled text-right mt-2 d-block">
                      <template v-if="article.author">
                        <span>by {{ article.author.username }}</span>
                        <span class="mx-1">&boxv;</span>
                      </template>
                      <span>{{ article.publishedAt | moment('LLL') }}</span>
                    </small>
                  </v-col>
                </v-row>
              </v-hover>

              <v-divider class="my-3" />
            </template>
          </v-container>
        </v-skeleton-loader>

        <v-row>
          <v-spacer/>
          <v-col style="flex-grow: 0">
            <v-pagination circle v-model="display_page" :length="pages_available"/>
          </v-col>
          <v-spacer class="d-sm-none"/>
          <v-col cols="4" sm="3" md="2">
            <v-select label="per page" class="mt-0" :items="[10, 20, 50]" v-model="per_page"></v-select>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  async mounted() {
    await this.load_count();

    this.per_page = Number(this.$route.query.per_page) || 10;
    this.display_page = Number(this.$route.query.page) || 1;

    await this.updateCurrentPageItems();

    await this.$vuetify.goTo(this.$refs.news_header);
  },

  data() {
    return {
      per_page: 10,
      current_page: 0,

      current_page_items: [],
    }
  },

  computed: {
    ...mapGetters({
      items_available: 'articles/count'
    }),

    pages_available() {
      return Math.max(Math.ceil(this.items_available / this.per_page), 1);
    },

    display_page: {
      get() {
        return Math.max(this.current_page + 1, 1);
      },
      set(v) {
        return this.current_page = v - 1;
      },
    },

    coordinates() {
      return {
        page: this.current_page,
        per_page: this.per_page
      };
    }
  },

  watch: {
    per_page() {
      this.current_page = 0;
    },

    async coordinates() {
      await this.updateCurrentPageItems();

      await this.updateLocation();
    },

    '$route.query.page': async function () {
      this.display_page = Number(this.$route.query.page) || 1;

      await this.updateCurrentPageItems();
    }
  },

  methods: {
    ...mapActions({
      load_count: 'articles/loadCount',
      get_items: 'articles/get'
    }),

    async updateCurrentPageItems() {
      if (this.current_page > this.pages_available - 1 || this.current_page < 0) {
        this.current_page = 0;

        await this.updateLocation(true);
      }

      let items = await this.get_items({
        start: this.current_page * this.per_page,
        limit: this.per_page
      });

      this.current_page_items.splice(0, this.current_page_items.length);
      this.current_page_items.push(...items);
    },

    async updateLocation(replace = false) {
      try {
        let loc = {
          name: 'articles',
          query: {}
        };

        if (this.display_page > 1)
          loc.query['page'] = this.display_page.toString();

        if (this.per_page !== 10)
          loc.query['per_page'] = this.per_page.toString();

        if (replace)
          await this.$router.replace(loc);
        else {
          await this.$router.push(loc);

          await this.$vuetify.goTo(this.$refs.news_header);
        }
      } catch (e) {
        // ignored
      }
    }
  }
}
</script>

<style scoped>
.article-row {
  transition: all 0.2s;
}
</style>
